import PropTypes from 'prop-types';
import * as Styles from './styles';

const Drawer = (props) => {
  return (
    <Styles.Drawer show={props.show}>
      <Styles.Close>
        <i className="fas fa-times" onClick={props.close} />
      </Styles.Close>
      <Styles.Ul>
        {props.links.map((el) => (
          <Styles.Li key={el.title}>
            <Styles.NavLink to={el.path} onClick={props.close}>
              {el.title}
            </Styles.NavLink>
          </Styles.Li>
        ))}
      </Styles.Ul>
    </Styles.Drawer>
  );
};

Drawer.propTypes = {
  links: PropTypes.arrayOf(Object),
  show: PropTypes.bool,
  close: PropTypes.func,
};

export default Drawer;
