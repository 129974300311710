import home from './home';
import about from './about';
// import blog from './blog';
import contact from './contact';

const navbar = {
  title: 'Nicholas Chumney',
  links: [
    { title: 'Home', path: home.path },
    // { title: 'Blog', path: blog.path },
    { title: 'About', path: about.path },
    { title: 'Contact', path: contact.path },
  ],
};

export default navbar;
