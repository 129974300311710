import PropTypes from 'prop-types';
import CardInfo from '../CardInfo';
import * as Styles from './styles';

const Card = (props) => {
  return (
    <Styles.Card>
      <Styles.Image src={props.image} alt={props.title} />
      <CardInfo
        title={props.title}
        subtitle={props.subtitle}
        live={props.live}
        repo={props.repo}
      />
    </Styles.Card>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  live: PropTypes.string,
  repo: PropTypes.string,
};

export default Card;
