import { useState } from 'react';
import PropTypes from 'prop-types';
import * as Styles from './styles';
import Navigation from '../Navigation';
import Drawer from '../Drawer';

const Navbar = (props) => {
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <Styles.Navbar>
      <Styles.Container>
        <Styles.Brand>{props.title}</Styles.Brand>
        <Navigation links={props.links} open={() => setShowDrawer(true)} />
      </Styles.Container>
      <Drawer
        links={props.links}
        show={showDrawer}
        close={() => setShowDrawer(false)}
      />
    </Styles.Navbar>
  );
};

Navbar.propTypes = {
  title: PropTypes.string,
  links: PropTypes.arrayOf(Object),
};

export default Navbar;
