import PropTypes from 'prop-types';
import * as Styles from './styles';

const Navigation = (props) => {
  return (
    <Styles.Navigation>
      <Styles.Menu onClick={props.open}>
        <i className="fas fa-bars" />
      </Styles.Menu>
      <Styles.Ul>
        {props.links.map((el) => (
          <Styles.Li key={el.title}>
            <Styles.NavLink to={el.path}>{el.title}</Styles.NavLink>
          </Styles.Li>
        ))}
      </Styles.Ul>
    </Styles.Navigation>
  );
};

Navigation.propTypes = {
  links: PropTypes.arrayOf(Object),
  open: PropTypes.func,
};

export default Navigation;
