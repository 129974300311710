import PropTypes from 'prop-types';
import * as Styles from './styles';

const Content = (props) => {
  return (
    <Styles.Content image={props.image}>
      {props.image && <Styles.Image src={props.image} alt={props.title} />}
      <div>
        {props.title && <Styles.Title>{props.title}</Styles.Title>}

        {props.noText ? (
          props.children
        ) : (
          <Styles.Text>{props.children}</Styles.Text>
        )}
      </div>
    </Styles.Content>
  );
};

Content.defaultProps = {
  noText: false,
};

Content.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  noText: PropTypes.bool,
};

export default Content;
