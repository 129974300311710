import PropTypes from 'prop-types';
import { useSpring } from 'react-spring';
import * as Styles from './styles';

const CardInfo = (props) => {
  const style = useSpring({
    opacity: 1,
    from: { opacity: 0 },
  });

  return (
    <Styles.CardInfo style={style}>
      <Styles.Title>{props.title}</Styles.Title>
      <Styles.Subtitle>{props.subtitle}</Styles.Subtitle>
      <div style={{ flexGrow: 1 }} />
      <Styles.Links>
        {props.live && (
          <Styles.Link href={props.live} target="_blank" rel="noreferrer">
            Live
          </Styles.Link>
        )}
        {props.repo && (
          <Styles.Link href={props.repo} target="_blank" rel="noreferrer">
            Repo
          </Styles.Link>
        )}
      </Styles.Links>
    </Styles.CardInfo>
  );
};

CardInfo.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  live: PropTypes.string,
  repo: PropTypes.string,
};

export default CardInfo;
