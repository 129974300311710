import styled from 'styled-components';
import { animated } from 'react-spring';
import { color } from '../../helpers/themes';

export const CardInfo = styled(animated.div)`
  flex-grow: 1;
  min-height: 100px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  font-size: 1.5rem;
`;

export const Subtitle = styled.p`
  font-size: 1rem;
  padding-bottom: 1rem;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const Link = styled.a`
  text-decoration: none;
  padding: 0.8rem 1rem;
  font-size: 1rem;
  background: ${color.black};
  color: ${color.white};
  &:hover {
    color: ${color.red};
  }
`;
