import PropTypes from 'prop-types';
import Hero from '../../components/Hero';
import Content from '../../components/Content';
import IconContainer from '../../components/IconContainer';
import Icon from '../../components/Icon';
import me from '../../helpers/assets/me.jpg';

const About = (props) => {
  return (
    <>
      <Hero title={props.title} />
      <Content image={me}>
        Hi, my name is Nicholas Chumney and I have an interest in all things
        software. Based out of Pickering, ON, I got my bachelor's in
        Mechatronics engineering and management from McMaster university and
        after spending some time working in the manufacturing industry, I am now
        looking to make the leap into software development. Currently, I am most
        proficient with web development, mainly working with Node.js and React,
        but in the future I am looking to explore more around desktop and mobile
        development.
      </Content>
      <IconContainer title={'What I Know'}>
        <Icon>
          <i className="fab fa-html5"></i>
          <span>HTML5</span>
        </Icon>
        <Icon>
          <i className="fab fa-css3"></i>
          <span>CSS3</span>
        </Icon>
        <Icon>
          <i className="fab fa-js"></i>
          <span>Javascript</span>
        </Icon>
        <Icon>
          <i className="fab fa-react"></i>
          <span>React</span>
        </Icon>
        <Icon>
          <i className="fab fa-node"></i>
          <span>Node.js</span>
        </Icon>
        <Icon>
          <i className="fab fa-python"></i>
          <span>Python 3</span>
        </Icon>
      </IconContainer>
    </>
  );
};

About.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
};

export default About;
