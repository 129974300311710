import PropTypes from 'prop-types';
import Hero from '../../components//Hero';
import CardGrid from '../../components/CardGrid';
import IconContainer from '../../components/IconContainer';
import Icon from '../../components/Icon';

const Home = (props) => {
  return (
    <>
      <Hero title={props.title} subtitle={props.subtitle} text={props.text} />
      <IconContainer>
        <Icon link={'mailto:nchumney@hotmail.com'}>
          <i className="fas fa-envelope icon"></i>
        </Icon>
        <Icon link={'https://github.com/chumnend'}>
          <i className="fab fa-github icon"></i>
        </Icon>
        <Icon link={'https://www.linkedin.com/in/nicholaschumney'}>
          <i className="fab fa-linkedin icon"></i>
        </Icon>
      </IconContainer>
      <CardGrid items={props.projects} />
    </>
  );
};

Home.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  projects: PropTypes.arrayOf(Object),
};

export default Home;
