import PropTypes from 'prop-types';
import * as Styles from './styles';

const Hero = (props) => {
  return (
    <Styles.Hero>
      <Styles.Container>
        {props.title && <Styles.Title>{props.title}</Styles.Title>}
        {props.subtitle && <Styles.Subtitle>{props.subtitle}</Styles.Subtitle>}
        {props.text && <Styles.Text>{props.text}</Styles.Text>}
      </Styles.Container>
    </Styles.Hero>
  );
};

Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
};

export default Hero;
