import navbar from './navbar';
import home from './home';
import about from './about';
import blog from './blog';
import contact from './contact';

const config = {
  // Navbar Configuration
  navbar: navbar,

  // Page Configurations
  home: home,
  about: about,
  blog: blog,
  contact: contact,

  // EmailJS Setup
  userId: process.env.REACT_APP_EMAILJS_USERID,
  templateId: process.env.REACT_APP_EMAILJS_TEMPLATEID,
  serviceId: process.env.REACT_APP_EMAILJS_SERVICEID,
};

export default config;
