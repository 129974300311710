import { BrowserRouter } from 'react-router-dom';
import Navbar from '../Navbar';
import Main from '../Main';
import Footer from '../Footer';
import Router from '../Router';
import config from '../../helpers/config';

const App = () => {
  return (
    <BrowserRouter>
      <Navbar title={config.navbar.title} links={config.navbar.links} />
      <Main>
        <Router />
      </Main>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
