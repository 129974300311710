import styled from 'styled-components';
import { device, color } from '../../helpers/themes';

export const IconContainer = styled.div`
  width: 100%;
  height: auto;
  margin: 1rem auto;
  padding: 1rem;

  @media all and (min-width: ${device.md}) {
    width: 90%;
    box-shadow: 0px 0px 3px 1px ${color.grey};
    border-radius: 10px;
  }
`;

export const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

export const Title = styled.h2`
  text-transform: uppercase;
  text-align: center;
`;
