import { useState } from 'react';
import PropTypes from 'prop-types';
import emailjs from 'emailjs-com';
import * as Styles from './styles';
import config from '../../helpers/config';

const ContactForm = (props) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [buttonText, setButtonText] = useState('Send Message');
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  const invalidForm = () => {
    return name.length === 0 || email.length === 0 || message.length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSending(true);
    setButtonText('Sending...');

    const templateParams = {
      senderName: name,
      senderEmail: email,
      senderMessage: message,
    };

    const { serviceId, templateId, userId } = config;
    emailjs
      .send(serviceId, templateId, templateParams, userId)
      .then(() => {
        clearInputs();
        setButtonText('Message Sent.');
        setSent(true);
      })
      .catch(() => {
        setButtonText('Error. Try Again?');
      });

    setSending(false);
  };

  const clearInputs = () => {
    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <Styles.Form onSubmit={handleSubmit}>
      <Styles.Header>
        <p>
          <strong>Email: </strong>
          {props.email}
        </p>
        <p>
          <strong>Phone: </strong>
          {props.phone}
        </p>
      </Styles.Header>
      <Styles.FormGroup>
        <Styles.Label htmlFor="name">Name</Styles.Label>
        <Styles.Input
          type="text"
          id="name"
          name="name"
          value={name}
          onChange={(event) => setName(event.target.value)}
          placeholder="Enter name"
        />
      </Styles.FormGroup>
      <Styles.FormGroup>
        <Styles.Label htmlFor="email">Email</Styles.Label>
        <Styles.Input
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          placeholder="Enter email"
        />
      </Styles.FormGroup>
      <Styles.FormGroup>
        <Styles.Label htmlFor="message">Message</Styles.Label>
        <Styles.TextArea
          id="message"
          name="message"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          placeholder="Enter message"
          rows="5"
        />
      </Styles.FormGroup>
      <Styles.Button disabled={sending || sent || invalidForm()}>
        {buttonText}
      </Styles.Button>
    </Styles.Form>
  );
};

ContactForm.propTypes = {
  email: PropTypes.string,
  phone: PropTypes.string,
};

export default ContactForm;
