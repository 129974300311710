import styled from 'styled-components';
import { device } from '../../helpers/themes';

export const CardGrid = styled.div`
  width: 90%;
  margin: 2rem auto;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;

  @media all and (min-width: ${device.sm}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media all and (min-width: ${device.md}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media all and (min-width: ${device.xl}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
