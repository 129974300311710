import PropTypes from 'prop-types';
import Card from '../Card';
import * as Styles from './styles';

const CardGrid = (props) => {
  return (
    <Styles.CardGrid>
      {props.items.map((el) => (
        <Card
          key={el.id}
          title={el.title}
          subtitle={el.subtitle}
          image={el.image}
          live={el.live}
          repo={el.repo}
        />
      ))}
    </Styles.CardGrid>
  );
};

CardGrid.propTypes = {
  items: PropTypes.arrayOf(Object),
};

export default CardGrid;
