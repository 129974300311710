import styled from 'styled-components';
import { device, color } from '../../helpers/themes';

export const Form = styled.form`
  width: 100%;
  height: auto;
  margin: 1rem auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  @media all and (min-width: ${device.md}) {
    width: 90%;
    max-width: 480px;
    box-shadow: 0px 0px 3px 1px ${color.grey};
    border-radius: 10px;
  }
`;

export const Header = styled.div`
  padding: 1rem 0;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.67rem 0;
`;

export const Label = styled.label`
  margin-bottom: 0.5rem;
  text-transform: uppercase;
`;

export const Input = styled.input`
  padding: 0.5rem 1rem;
  font-family: inherit;
`;

export const TextArea = styled.textarea`
  padding: 0.5rem 1rem;
  font-family: inherit;
`;

export const Button = styled.button`
  text-transform: uppercase;
  padding: 1rem;
  background: none;
  cursor: pointer;
  border: 1px solid ${color.black};
  margin: 0 auto;
  &:disabled {
    color: #bababa;
    border-color: #bababa;
  }
  &:hover:not([disabled]) {
    color: ${color.red};
    border-color: ${color.red};
  }
`;
