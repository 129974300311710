import styled from 'styled-components';
import { device, color } from '../../helpers/themes';

export const Content = styled.div`
  width: 100%;
  height: auto;
  margin: 1rem auto;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  align-items: center;
  justify-content: center;

  @media all and (min-width: ${device.md}) {
    width: 90%;
    grid-template-columns: ${(props) => (props.image ? '1fr 2fr' : '1fr')};
    box-shadow: 0px 0px 3px 1px ${color.grey};
    border-radius: 10px;
  }
`;

export const Image = styled.img`
  width: 100%;
  max-width: 300px;
  height: auto;
  margin: 0 auto;
  border-radius: 50%;
`;

export const Title = styled.h2`
  text-transform: uppercase;
  text-align: center;
`;

export const Text = styled.p`
  text-align: justify;
  padding: 1rem;

  @media all and (min-width: ${device.md}) {
    max-width: 800px;
  }
`;
