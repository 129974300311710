import PropTypes from 'prop-types';
import * as Styles from './styles';

const IconContainer = (props) => {
  return (
    <Styles.IconContainer>
      {props.title && <Styles.Title>{props.title}</Styles.Title>}
      <Styles.Container>{props.children}</Styles.Container>
    </Styles.IconContainer>
  );
};

IconContainer.propTypes = {
  title: PropTypes.string,
};

export default IconContainer;
