import PropTypes from 'prop-types';
import * as Styles from './styles';

const Icon = (props) => {
  if (props.link) {
    return (
      <Styles.IconLink href={props.link} target="_blank" rel="noreferrer">
        {props.children}
      </Styles.IconLink>
    );
  } else {
    return <Styles.Icon>{props.children}</Styles.Icon>;
  }
};

Icon.propTypes = {
  link: PropTypes.string,
};

export default Icon;
