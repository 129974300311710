import styled from 'styled-components';
import { color } from '../../helpers/themes';

export const Card = styled.div`
  width: 100%;
  box-shadow: 0px 0px 3px 1px ${color.grey};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

export const Image = styled.img`
  width: 100%;
  height: 250px;
`;
