import doodleIt from '../assets/doodle-it-capture.gif';
import flashcards from '../assets/flashcards-capture.gif';
import newsly from '../assets/newsly-capture.gif';
import pixahunt from '../assets/pixahunt-capture.gif';
import simplechat from '../assets/simple-chat-capture.gif';

const home = {
  path: '/',
  title: "Hi, I'm Nicholas",
  subtitle: 'Software Enthusiast and Free Mind',
  text: 'Check out my projects below!',
  projects: [
    {
      id: 0,
      title: 'Doodle It',
      subtitle: 'A graphic design tool',
      image: doodleIt,
      live: null,
      repo: 'https://github.com/chumnend/doodle-it',
    },
    {
      id: 1,
      title: 'Flash Cards',
      subtitle: 'A social media application for sharing flash cards',
      image: flashcards,
      live: null,
      repo: 'https://github.com/chumnend/flash-cards',
    },
    {
      id: 2,
      title: 'Newsly',
      subtitle: 'A news headline scrapping application',
      image: newsly,
      live: null,
      repo: 'https://github.com/chumnend/newsly',
    },
    {
      id: 3,
      title: 'Pixahunt',
      subtitle: 'Find stock pictures',
      image: pixahunt,
      live: null,
      repo: 'https://github.com/chumnend/pixahunt',
    },
    {
      id: 4,
      title: 'Simple Chat',
      subtitle: 'A chat application using Socket.io',
      image: simplechat,
      live: null,
      repo: 'https://github.com/chumnend/simple-chat',
    },
  ],
};

export default home;
