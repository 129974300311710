import { Switch, Route } from 'react-router-dom';
import Home from '../../containers/Home';
import About from '../../containers/About';
import Blog from '../../containers/Blog';
import Contact from '../../containers/Contact';
import NotFound from '../../containers/NotFound';
import config from '../../helpers/config';

const Router = () => {
  return (
    <Switch>
      <Route
        exact
        path={config.about.path}
        render={() => <About title={config.about.title} />}
      />
      <Route
        exact
        path={config.blog.path}
        render={() => <Blog title={config.blog.title} />}
      />
      <Route
        exact
        path={config.contact.path}
        render={() => (
          <Contact
            title={config.contact.title}
            email={config.contact.email}
            phone={config.contact.phone}
          />
        )}
      />
      <Route
        exact
        path={config.home.path}
        render={() => (
          <Home
            title={config.home.title}
            subtitle={config.home.subtitle}
            text={config.home.text}
            projects={config.home.projects}
          />
        )}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Router;
