import PropTypes from 'prop-types';
import Hero from '../../components/Hero';
import ContactForm from '../../components/ContactForm';

const Contact = (props) => {
  return (
    <>
      <Hero title={props.title} />
      <ContactForm email={props.email} phone={props.phone} />
    </>
  );
};

Contact.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
};

export default Contact;
