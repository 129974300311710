const blog = {
  path: '/blog',
  title: 'Future Blog Spot',
  posts: [
    {
      id: 1,
      title: 'Lorem ipsum dolor sit amet',
      date: 'February 2, 2021',
      content: 'test',
      path: '/blog/test-blog-1',
    },
    {
      id: 2,
      title: 'Lorem ipsum dolor sit amet',
      date: 'February 2, 2021',
      content: 'test',
      path: '/blog/test-blog-2',
    },
    {
      id: 3,
      title: 'Lorem ipsum dolor sit amet',
      date: 'February 2, 2021',
      content: 'test',
      path: '/blog/test-blog-3',
    },
  ],
};

export default blog;
