import React from 'react';
import PropTypes from 'prop-types';
import * as Styles from './styles';

const Main = (props) => {
  return <Styles.Main>{props.children}</Styles.Main>;
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
