import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { device, color } from '../../helpers/themes';

export const Drawer = styled.div`
  width: ${(props) => (props.show ? '60%' : '0')};
  height: 100vh;
  background: ${color.black};
  color: ${color.white};
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  transition: width 0.3s ease-out;
  display: flex;
  flex-flow: column;

  @media all and (min-width: ${device.lg}) {
    display: none;
  }
`;

export const Close = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row-reverse;

  & i {
    font-size: 1.5rem;
    cursor: pointer;
  }
`;

export const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
`;

export const Li = styled.li`
  display: inline-block;
  padding: 1rem;
`;

export const NavLink = styled(Link)`
  text-decoration: none;
  background: inherit;
  color: inherit;
  font-size: 1.2rem;
  &:hover {
    color: ${color.red};
  }
`;
