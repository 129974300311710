import Hero from '../../components/Hero';

const NotFound = () => {
  return (
    <>
      <Hero title={'Oops, this page does not exist!'} />
    </>
  );
};

export default NotFound;
