import * as Styles from './styles';

const Footer = () => {
  return (
    <Styles.Footer>
      <Styles.Container>
        <div>
          <Styles.Icon
            href="mailto:nchumney@hotmail.com"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fas fa-envelope icon" />
          </Styles.Icon>
          <Styles.Icon
            href="https://github.com/chumnend"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-github icon" />
          </Styles.Icon>
          <Styles.Icon
            href="https://www.linkedin.com/in/nicholaschumney"
            target="_blank"
          >
            <i className="fab fa-linkedin icon" />
          </Styles.Icon>
        </div>
        <Styles.Brand>
          <i className="far fa-copyright"></i> Nicholas Chumney
        </Styles.Brand>
      </Styles.Container>
    </Styles.Footer>
  );
};

export default Footer;
