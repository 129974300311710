import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Content from '../../components/Content';
import Hero from '../../components/Hero';
import config from '../../helpers/config';

const Blog = (props) => {
  const history = useHistory();

  const goToPage = (path) => {
    history.push(path);
  };

  return (
    <>
      <Hero title={props.title} />
      <div>
        {config.blog.posts.map((post) => (
          <Content key={post.id} id={post.id} noText>
            <h2>{post.title}</h2>
            <h3>{post.date}</h3>
            <p>{post.content}</p>
            <button onClick={() => goToPage(post.path)}>Read</button>
          </Content>
        ))}
      </div>
    </>
  );
};

Blog.propTypes = {
  title: PropTypes.string,
};

export default Blog;
